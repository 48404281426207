import financeApi from '@/services/financeApi'
import envHelper from '@/utils/environment.helper'
import * as minVersions from "@/constants/minVersions";
import finesApi from '../services/finesApi';

const versionCompare = function({androidVer, iosVer}, currentVersion) {
  if (envHelper.IS_IOS) {
    return false;
  }
  const minVersion = (!envHelper.IS_ANDROID) ? iosVer : androidVer;

  const minAppVersion = String(minVersion).split(".");
  const currentAppVersion = String(currentVersion).split(".");

  const dots = Math.max(minAppVersion.length, currentAppVersion.length);

  const minAppVersionC = minAppVersion.reduce(
    (accumulator, currentValue, index) => accumulator + parseInt(currentValue) * Math.pow(1000, dots-index),
    0
  );
  const currentAppVersionC = currentAppVersion.reduce(
    (accumulator, currentValue, index) => accumulator + parseInt(currentValue) * Math.pow(1000, dots-index),
    0
  );

  return !!(currentAppVersionC && currentAppVersionC >= minAppVersionC);
}

const appVersion = {
  namespaced: true,
  state () {
    return {
      appVersion: null
    }
  },
  getters: {
    isNdflDeductionSupported(state) {
      return versionCompare(minVersions.NdflDeductionVer, state.appVersion);
    },
  },
  mutations: {
    setAppVersion(state, value) {
      state.appVersion = value;
    }
  },
  actions: {
    async loadAppVersion({state, commit, rootState}) {
      if (!rootState.isWeb) {
        if (!state.appVersion) {
          const response = await financeApi.get("/appversion").catch(async (e) => {
            console.error(e.toJSON());
          });
          if (financeApi.isSuccess(response)) {
            commit("setAppVersion", response.data.version);
          }
          return response;
        }
      }
    },
  }
}

 export default appVersion;
  