<template>
  <div class="content">
    <router-view />
  </div>
</template>
<script>

import { mapActions, mapMutations, mapState } from 'vuex';
import { statService } from '@/utils/statService';
import urlHelper from '@/utils/url.helper';
import finesApi from '@/services/finesApi';
import store from '@/store';

const GET = urlHelper.parseQueryString(location.href).GET;
if (GET.userId && GET.rand && GET.session) {
  const params = {response: GET};
  finesApi.updateRequestParams(params, false);
}
if (GET.channel) {
  localStorage.setItem("channel", GET.channel);
  statService.init({
    channel: GET.channel
  });
}
if (GET.source) {
  localStorage.setItem("source", GET.source);
}

// автоматическая авторизация отключена для дестопа
// store.dispatch("auth", localStorage.getItem("source"));

export default {
  name: 'App',
  computed: {
    ...mapState({
      globalLoading: (state) => state.globalLoading,
    }),
  },
  async mounted() {
    this.loaded();
    this.setGlobalLoading(false);
    statService.logEvent('[Ситуация] Приложение загружено');
    this.loadAppVersion();
  },
  methods: {
    ...mapActions({
      loaded: 'communications/sendLoadedEvent',
      loadAppVersion: 'appVersion/loadAppVersion',
    }),
    ...mapMutations({
      setGlobalLoading: 'setGlobalLoading',
    })
  },
}
</script>
