import osagoApi from '@/services/osagoApi'

const companies = {
  namespaced: true,
  state () {
    return {
      companiesList: []
    }
  },
  getters: {
    getCompaniesList(state) {
      return state.companiesList;
    }
  },
  mutations: {
    setCompanies(state, companies) {
      state.companiesList = companies;
    }
  },
  actions: {
    async loadInsuranceCompanies({state, commit}) {
      const response = await osagoApi.get("/partner/insurance-companies").catch(async (e) => {
        console.error(e);
      });
      if (osagoApi.isSuccess(response)) {
        commit("setCompanies", response.data);
      }
      return response;
    },
  }
}

 export default companies;
  