import * as VueRouter from 'vue-router'
import store from '@/store';

import MainLayout from "@/layouts/MainLayout";

const Main = () => import('@/pages/MainPage');

const Search = () => import('@/pages/Search');

let externalBasePath = "";
const scriptTag = document.getElementById("rf-finance-script");
if (scriptTag) {
  store.commit("setIsWeb", true);
  store.commit("externalAuth/setExternalAuth",{
    userId: scriptTag.getAttribute('data-user-id'),
    session: scriptTag.getAttribute('data-session'),
    rand: scriptTag.getAttribute('data-rand'),
  });
  store.commit("externalAuth/setYmId", scriptTag.getAttribute('data-ym-id'));
  store.commit("externalAuth/setGaId", scriptTag.getAttribute('data-ga-id'));
  if (scriptTag.getAttribute('data-base-path')) {
    externalBasePath = '/' + scriptTag.getAttribute('data-base-path');
  }
}
const basePath = externalBasePath || "";

const router = VueRouter.createRouter({
  mode: 'history',
  history: VueRouter.createWebHistory(),
  routes: [
    {
      path: basePath + '/',
      component: MainLayout,
      children: [
        { path: '', component: Main, name: 'main' },
        { path: 'search', component: Search, name: 'searchCredit' },
      ]
    },

    { path: '/:pathMatch(.*)*', redirect: { name: 'main' } }
  ]
});


const slugsMap = {
  search: 'Подбор кредита',
}

router.beforeEach(async (to, from, next) => {
  window.scroll({top: 0, behavior: "smooth"})
  let breadcrumbs = [];
  const pathItems = to.path.split("/");
  for(let i = 1; i < pathItems.length; i++) {
    const computedPath = pathItems.slice(0, i+1).join("/");
    if (computedPath === basePath) {
      breadcrumbs.push({
        href: computedPath,
        label: 'Финуслуги',
      });
    } else if (pathItems[i]) {
      breadcrumbs.push({
        href: computedPath,
        label: slugsMap[pathItems[i]],
      });
    }
  }
  window.setBreadcrumbs && window.setBreadcrumbs(breadcrumbs);

  if (!store.state.isWeb) {
    document.body.setAttribute("data-page", to.name);
  }

  next();
});

export default router;
