import { createStore } from 'vuex';

import finesApi from '@/services/finesApi';

import appVersion from "@/store/appVersion";
import communications from "@/store/communications";
import companies from "@/store/companies";
import externalAuth from '@/store/externalAuth';

export const store = createStore({
  modules: {
    appVersion,
    communications,
    companies,
    externalAuth,
  },
  state() {
    return {
      globalLoading: true,
      isWeb: false,
    };
  },
  mutations: {
    setGlobalLoading(state, val) {
      state.globalLoading = !!val;
    },
    setIsWeb(state, val) {
      state.isWeb = !!val;
    },
  },
  actions: {
    auth(context, source) {
      if (!finesApi.isAuth()) {
        finesApi.auth(source);
      }
    }
  }
});

export default store;
